<template>
  <div class="my-16">
    <div class="">
      <p class="font-bold text-base my-3" v-html="label"></p>
    </div>
    <form action="">
      <div v-for="answer in answerSet" :key="answer.id">
        <p class="my-2 bg-grey p-5 rounded max-w-5xl">
          <input
            type="radio"
            name="context"
            :id="answer.id"
            class="align-middle w-4 h-4 cursor-pointer"
            :value="answer.options"
            v-model="theValue"
          />
          <label
            :for="answer.id"
            class="align-middle leading-none cursor-pointer"
            ><span class="font-semibold ml-2">{{ answer.options }}.</span>
            {{ answer.label }}</label
          >
        </p>
      </div>
    </form>
    <!-- <p class="my-2">
      <input
        type="radio"
        name="context"
        id="falsy"
        class="align-middle w-4 h-4"
      />
      <label for="falsy" class="align-middle leading-none"
        ><span class="font-semibold ml-2">B.</span> Uninportant. It's all about
        getting what you want</label
      >
    </p> -->
  </div>
</template>

<script>
export default {
  name: "QuestionAnswer",
  props: ["answerSet", "label", "order", "q_id", "quizTwoSelections"],
  data() {
    return {
      theValue: "",
    };
  },
  watch: {
    theValue(newValue) {
      this.$emit("values", this.order, newValue);
      // find the id for the checked answer in the answerSet
      const answerId = this.answerSet.find(
        (answer) => answer.options === newValue
      ).id;
      this.$emit("answerData", this.q_id, answerId, newValue);
    },
  },

  methods: {
    checkValues() {
      let obj = this.quizTwoSelections[0];
      // console.log(this.quizTwoSelections);
      if (this.quizTwoSelections.length > 0) {
        for (const [key, value] of Object.entries(obj)) {
          // console.log(key, value);
          if (this.order == key) {
            this.theValue = value;
            // console.log(this.theValue);
          }
        }
      }
    },
  },

  mounted() {
    if (this.quizTwoSelections !== undefined) {
      this.checkValues();
    }
  },
};
</script>