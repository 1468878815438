<template>
  <div class="popup">
    <div
      @click="$emit('closePopup')"
      class="
        z-20
        overlay
        fixed
        top-0
        bottom-0
        right-0
        left-0
        bg-opacity-20
        backdrop-blur-md
        h-full
        bg-black
      "
    ></div>
    <div
      class="w-full flex absolute justify-center top-0 bottom-0 items-center"
    >
      <div
        class="
          popup-content
          fixed
          z-40
          rounded-large
          bg-white
          overflow-auto
          px-7
          py-10
          md:px-10
          w-4/5
          md:w-128
        "
      >
        <p class="font-semibold text-base mb-4">How did you find the test?</p>

        <p class="mb-1">
          <label for="">Add a headline</label>
        </p>
        <p>
          <input
            type="text"
            name=""
            id=""
            class="w-full p-3 border border-tetiary rounded"
          />
        </p>

        <p class="mb-1 mt-5">
          <label for="">Add a written review</label>
        </p>
        <p>
          <textarea
            type="t"
            name=""
            id=""
            rows="4"
            class="w-full p-3 border border-tetiary rounded"
          ></textarea>
        </p>

        <p class="mt-5">
          <router-link
            :to="{ name: 'SuccessPage' }"
            @click="closepopup"
            class="
              bg-tetiary
              px-8
              py-4
              bg-opacity-80
              rounded-md
              hover:bg-opacity-100
              text-white
              font-semibold
              block
              text-center
              cursor-pointer
              transition
              md:w-60
            "
            >Submit</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubmitPopup",
  methods: {
    closepopup() {
      document.body.style.overflow = "auto";
    },
  },
};
</script>

<style scoped>
.overlay {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
</style>