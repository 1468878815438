<template>
  <div
    class="
      instructions
      rounded-md
      p-6
      md:px-8
      lg:px-12 lg:py-9
      w-full
      background
      opacity-100
    "
  >
    <div class="">
      <p class="text-md lg:text-lg pb-5 text-secondary font-bold">
        Instructions:
      </p>
      <div class="text-white">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheInstructions",
};
</script>

<style scoped>
.instructions {
  box-shadow: 0px 5px 5px rgba(66, 93, 235, 0.15);
}
</style>