<template>
  <div class="navigation bg-white">
    <div
      class="
        container
        flex
        justify-between
        items-center
        max-w-screen-xxl
        m-auto
        md:py-5
        py-4
      "
    >
      <div class="logo w-28 md:w-44">
        <img src="@/assets/Images/home/logo.png" alt="precept" class="w-full" />
      </div>
      <div class="">
        <p>
          <font-awesome-icon
            icon="user-circle"
            class="
              p-1
              bg-slate
              text-secondary
              rounded-full
              align-middle
              md:text-base
              bx-sdw
            "
          ></font-awesome-icon>
          <span class="pl-2 align-middle md:text-base">{{ name }}</span>
        </p>
      </div>
      <!-- <div class="md:hidden">H</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TheNavigation",
  data() {
    return {
      name: this.$store.state.user.quizTaker.name,
    };
  },
};
</script>

<style scoped>
.navigation {
  box-shadow: 0px 4px 4px rgba(66, 93, 235, 0.15);
}
</style>