<template>
  <div class="popup">
    <div
      class="
        z-20
        overlay
        fixed
        top-0
        bottom-0
        right-0
        left-0
        bg-opacity-20
        backdrop-blur-md
        h-full
        bg-black
      "
    ></div>
    <div
      class="w-full flex absolute justify-center top-0 bottom-0 items-center"
    >
      <div
        class="
          popup-content
          fixed
          z-40
          rounded-large
          bg-white
          overflow-auto
          p-6
          md:w-96
          w-5/6
        "
      >
        <p class="font-bold text-primary md:text-md text-base">
          <slot name="title">Title</slot>
        </p>
        <p class="my-4 md:text-base">
          <slot name="message">This is where your message will go...</slot>
        </p>
        <div
          class="btn py-3 float-right px-6 text-center w-1/4"
          @click="$emit('close')"
        >
          Ok
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThePopup",
  data() {
    return {};
  },

  mounted() {
    document.body.style.overflow = "hidden";
  },

  beforeUnmount() {
    document.body.style.overflow = "auto";
  },
};
</script>